<template>
	<div>
		<!--企业资料-->
		<div class="company-info-box mt30" v-show="isShow">
			<h3>企业资料</h3>
			<div class="company-info">
				<ul>
					<li>
						<img :src="companyAccess.businessLicense" alt="" @click="handleBigImg(companyAccess.businessLicense)">
						<p>
							统一社会信用代码证<br/>
							（即三证合一的营业执照）
						</p>
					</li>
					<li>
						<img :src="companyAccess.legalIdCard" alt="" @click="handleBigImg(companyAccess.legalIdCard)">
						<p>
							法人代表身份证<br/>复印件（正反面）
						</p>
					</li>
				</ul>
			</div>
		</div>
		<!--银行账户资料-->
		<div class="bank-info-box" v-show="companyAccess.bankAccountList !== []">
			<div class="file-title">
				<h3>银行账户资料</h3>
			</div>
			<div class="upload-explain">
				<p>
					<img src="~@/assets/img/ticket/explain.png" alt="">上传说明：
				</p>
				<span>
					基本账户：开户许可证复印件<br/>
					一般账户：银行流水回单，网银截屏或者其他显示账户信息的复印件<br/>
          <!--个人账户：银行卡号正反面复印件或者存折账号页复印件，复印件上请写上开户姓名及开户网点-->
				</span>
			</div>
			<ul class="bank-info">
				<li v-for="item in companyAccess.bankAccountList"
				    :key="item.id"
						v-if="item.bankExtId !== null">
					<p>账号：{{item.bankNumber}}</p>
					<img :src="item.bankAccountData" alt="" @click="handleBigImg(item.bankAccountData)">
				</li>
			</ul>
		</div>
		<!--操作员资料-->
		<div class="operator-info-box" v-show="companyAccess.frontUserList !== []">
			<div class="file-title">
				<h3>操作员资料</h3>
			</div>
			<el-form>
				<el-form-item v-for="item in companyAccess.frontUserList"
				              :label="item.userName"
				              :key="item.id"
				              label-width="100px"
											v-if="item.operationExtId !== null">
					<p>
						<img :src="item.idCardFrontBack" alt="" @click="handleBigImg(item.idCardFrontBack)">
						<span>身份证复印件（正反面）</span>
					</p>
					<p>
						<a 
							:href="item.authorizationEntrustBook" 
							v-if="item.authorizationEntrustBook.substring(item.authorizationEntrustBook.length-3 === 'pdf')"
							target="_blank">
							<img :src="pdfImg" alt="">
						</a>
						<img :src="item.authorizationEntrustBook" alt="" @click="handleBigImg(item.authorizationEntrustBook)" v-else>
						<span>授权委托书</span>
					</p>
				</el-form-item>
			</el-form>
		</div>
		<!-- 控制大图显示的弹框 -->
		<el-dialog
			:visible.sync="bigImgDialogVisible"
			width="50%"
			center>
			<img :src="bigImgUrl" alt="" class="big-img-url">
		</el-dialog>
	</div>
</template>

<script>
  export default {
    name: "AccessTwo",
	  props:['companyAccess'],
    computed: {
      isShow(){
        if (this.companyAccess.businessLicense !== [] || this.companyAccess.legalIdCard !==[]){
          return true
        }else {
          return false
        }
      }
    },
		data(){
    	return {
				pdfImg: 'https://cpiaoju.oss-cn-beijing.aliyuncs.com/lxgw/gpxt/upload_image/pdf.png', //合同附件上传pdf时展示的固定图片
        bigImgUrl: '', //控制大图显示的图片
				bigImgDialogVisible: false, // 控制大图展示的显示与隐藏
			}
		},
    methods: {
      handleBigImg(value){
				this.bigImgUrl = value
				this.bigImgDialogVisible = true
      }
    }
  }
</script>

<style lang="less" scoped>
	.verify-code{
		width: 220px;
	}
	.company-info{
		ul{
			display: flex;
			margin-top: 20px;
			li{
				width: 178px;
				margin-right: 20px;
				img{
					width: 178px;
					height: 178px;
				}
				p{
					font-size: 14px;
					text-align: center;
					margin-top: 10px;
				}
			}
		}
	}
	
	/*银行账户资料*/
	.bank-info-box{
		.upload-explain{
			background: #FFFBE6;
			width: 100%;
			line-height: 1.8;
			padding: 10px 15px;
			margin-top: 20px;
			border: 1px solid #FFFBE6;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			span{
				display: flex;
				line-height: 1.8;
				color: #666;
				padding-left: 20px;
			}
			img{
				vertical-align: -2px;
				margin-right: 5px;
			}
		}
		.bank-info{
			li{
				margin-top: 20px;
				.upload-demo{
					margin-top: 10px;
					margin-left: 40px;
				}
				img{
					width: 178px;
					height: 178px;
					margin-top: 10px;
					margin-left: 30px;
				}
			}
		}
	}
	/*操作员资料*/
	.operator-info-box{
		.el-form-item{
			margin-top: 20px;
			/deep/ .el-form-item__content{
				width: 90%;
				p{
					display: inline-block;
					width: 178px;
					margin-right: 20px;
					span{
						display: block;
						width: 178px;
						line-height: 1.5 !important;
						text-align: center;
					}
					img{
						width: 178px;
						height: 178px;
					}
				}
			}
		}
	}
	.big-img-url{
		display: block;
		width: 100%;
		height: 500px;
	}
</style>