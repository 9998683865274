<template>
	<div v-cloak v-show="isShow" class="accessDetail">
		<div class="status" v-if="auditStatusName">
			<span>任务状态：{{auditStatusName}}</span>
			<span v-if="detail.auditStatus !== 0 && detail.auditOpinion !== null">审核意见：{{detail.auditOpinion}}</span>
		</div>
		<!--tab切换-->
		<el-tabs v-model="activeName" type="border-card" class="mt20">
			<el-tab-pane label="企业准入信息" name="first">
				<AccessDetailOne :companyAccess="detail"/>
			</el-tab-pane>
			<el-tab-pane label="企业资料补充" name="second">
				<AccessDetailTwo :companyAccess="detail"/>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	import AccessDetailOne from './childCpn/AccessDetailOne'
	import AccessDetailTwo from './childCpn/AccessDetailTwo'
  export default {
    name: "AccessDetail",
	  props: ['detail'],
	  components: {
      AccessDetailOne,
      AccessDetailTwo
	  },
	  data(){
      return {
        activeName: 'first',// 当前激活的标签
	      isShow: false, //判断是否展示详情页
      }
	  },
    watch: {
      // 监听是否展示详情
      detail: {
        handler(newValue){
          if (newValue.auditStatus !== 3 || newValue.auditStatus !== 4 || newValue.auditStatus !== 5){
            this.isShow = true
          }else {
            this.isShow = false
          }
        },
        deep: true,
        immediate: true
      }
    },
	  computed: {
      // 任务状态
      auditStatusName(){
        if (this.detail.auditStatusName){
          return this.detail.auditStatusName
        }else {
          return  ''
        }
      }
	  }
	  
  }
</script>

<style lang="less" scoped>
	[v-cloak]{
		display: none !important;
	}
	.el-alert{
		padding: 16px !important;
		margin-top: 10px;
		/deep/ .el-alert__title{
			font-size: 15px;
		}
	}
	.status{
		background: #fdf6ec;
		width: 100%;
		height: 50px;
		line-height: 50px;
		color: #E6A23C;
		padding: 0 20px;
		margin-top: 10px;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
	}
</style>