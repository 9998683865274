<template>
	<div class="licence">
		<p>
			<slot name="licence"></slot>
		</p>
		<ul>
			<li v-for="item in list" :key="item.id">
				<span>{{item.protocolTitle}}</span>
				<a :href="item.downloadUrl" target="_blank">查看</a>
			</li>
		</ul>
	</div>
	
</template>

<script>
  export default {
    name: "Licence",
	  props: ['list']
  }
</script>

<style lang="less" scoped>
	/*签署协议*/
	.licence{
		.agree-img{
			width: 18px;
			height: 18px;
			margin-right: 6px;
		}
		p{
			background: #FAFAFA;
			width: 100%;
			height: 54px;
			margin-top: 20px;
			padding-left: 20px;
			box-sizing: border-box;
			display: flex;
			align-items: center;
		}
		li{
			padding: 15px;
			border-bottom: 1px solid #E8E8E8;
			display: flex;
			justify-content: space-between;
			box-sizing: border-box;
		span{
			font-size: 14px;
			color: #333;
		}
		a{
			color: #C70009;
		}
		}
	}
</style>