<template>
	<div>
		<!--企业信息-->
		<el-descriptions title="企业信息" :column="2" class="mt30">
			<el-descriptions-item label="企业名称">{{companyAccess.companyName}}</el-descriptions-item>
			<el-descriptions-item label="统一社会信息代码">{{companyAccess.socCode}}</el-descriptions-item>
			<el-descriptions-item label="法人姓名">{{companyAccess.legalName}}</el-descriptions-item>
			<el-descriptions-item label="法人手机号码">{{companyAccess.legalTel}}</el-descriptions-item>
			<el-descriptions-item label="法人证件类型">{{companyAccess.legalIdType}}</el-descriptions-item>
			<el-descriptions-item label="法人证件号码">{{companyAccess.legalIdNo}}</el-descriptions-item>
		</el-descriptions>
		<!--基本信息-->
		<el-descriptions title="基本信息" :column="2" class="mt20">x
			<el-descriptions-item label="联系电话">{{companyAccess.companyPhone}}</el-descriptions-item>
			<el-descriptions-item label="企业所在省">{{companyAccess.workProvince}}</el-descriptions-item>
			<el-descriptions-item label="企业所在市">{{companyAccess.workCity}}</el-descriptions-item>
			<el-descriptions-item label="注册地址">{{companyAccess.regAddress}}</el-descriptions-item>
			<el-descriptions-item label="通讯地址">{{companyAccess.commAddr}}</el-descriptions-item>
		</el-descriptions>
		<!--企业登记信息-->
		<el-descriptions title="企业登记信息" :column="2" class="mt20">
			<el-descriptions-item label="行业分类">{{companyAccess.industryCategory}}</el-descriptions-item>
			<el-descriptions-item label="是否涉农">
				{{companyAccess.isAgriculture == 1 ? '是' : '否'}}
			</el-descriptions-item>
			<el-descriptions-item label="是否绿色">
				{{companyAccess.isGreen == 1 ? '是' : '否'}}
			</el-descriptions-item>
			<el-descriptions-item label="客户性质">{{companyAccess.customerType}}</el-descriptions-item>
			<el-descriptions-item label="是否科技">
				{{companyAccess.isTechnology == 1 ? '是' : '否'}}
			</el-descriptions-item>
			<el-descriptions-item label="企业规模">{{enterpriseScale}}</el-descriptions-item>
		</el-descriptions>
		<!--账户信息-->
		<h3 class="mt20">账户信息</h3>
    <WarmPrompt></WarmPrompt>
		<el-table :data="companyAccess.bankAccountList" border>
			<el-table-column prop="bankName" label="账户名称" min-width="100px"></el-table-column>
			<el-table-column prop="bankNumber" label="账号" min-width="180px"></el-table-column>
			<el-table-column prop="openBank" label="开户行" min-width="80px"></el-table-column>
			<el-table-column prop="provinceId" label="开户省" min-width="80px"></el-table-column>
			<el-table-column prop="cityId" label="开户市" min-width="80px"></el-table-column>
			<el-table-column prop="clearingNumber" label="联行号" min-width="120px"></el-table-column>
			<el-table-column prop="bankBranch" label="所属支行名称" min-width="150px"></el-table-column>
			<el-table-column prop="cfcaBankCodeName" label="开户行（票交所）" min-width="150px"></el-table-column>
			<el-table-column prop="isCloseAccount" label="是否结算账户" min-width="120px">
				<template v-slot="scope">
					<span v-if="scope.row.isCloseAccount === null"></span>
					<span v-else-if="scope.row.isCloseAccount === 1">是</span>
					<span v-else>否</span>
				</template>
			</el-table-column>
			<el-table-column prop="accountType" label="账户类别" min-width="120px"></el-table-column>
			<el-table-column prop="accountStartDay" label="账户生效日" min-width="120px"></el-table-column>
			<el-table-column prop="accountEndDay" label="账户到期日" min-width="120px"></el-table-column>
		</el-table>
		<!--账户信息-->
		<h3 class="mt20">操作员信息</h3>
		<el-table :data="companyAccess.frontUserList" border>
			<el-table-column prop="userName" label="操作员姓名" min-width="100px"></el-table-column>
			<el-table-column prop="userPhone" label="操作员手机号码" min-width="120px"></el-table-column>
			<el-table-column prop="certificateType" label="操作员证件类型" min-width="120px"></el-table-column>
			<el-table-column prop="certificateNumber" label="操作员证件号码" min-width="180px"></el-table-column>
			<el-table-column prop="userEmail" label="操作员邮箱" min-width="120px"></el-table-column>
			<el-table-column prop="roleNameListStr" label="操作员角色" min-width="100px"></el-table-column>
			<el-table-column prop="postType" label="操作员职位类型" min-width="130px"></el-table-column>
			<el-table-column prop="postLevel" label="操作员职位级别" min-width="180px"></el-table-column>
		</el-table>
		<!--签署协议-->
		<h3 class="mt30">签署协议</h3>
		<Licence :list="companyAccess.protocolList">
			<template #licence>
				<img src="~@/assets/img/ticket/agree.png" alt="" class="agree-img">同意签署以下协议
			</template>
		</Licence>
	</div>
</template>

<script>
  /*协议*/
	import Licence from "./Licence";
  /*温馨提示*/
  import WarmPrompt from '@/views/Ticket/childrenCpn/WarmPrompt/Index'
  export default {
    name: "AccessDetailOne",
	  props: ['companyAccess'],
	  components: {
      Licence,
      WarmPrompt
	  },
		computed:{
			enterpriseScale(){
				if(this.companyAccess.enterpriseScale == '1'){
					return '小于20人'
				}else if(this.companyAccess.enterpriseScale == '2'){
					return '20-300人'
				}else if(this.companyAccess.enterpriseScale == '3'){
					return '300-1000人'
				}else if(this.companyAccess.enterpriseScale == '4'){
					return '大于1000人'
				}
			}
		}
  }
</script>

<style lang="less" scoped>
	.el-table{
		margin-top: 20px;
	}
	.el-descriptions{
		/deep/ .el-descriptions__body .el-descriptions__table .el-descriptions-item__cell{
			line-height: 2.5 !important;
		}
		/deep/ .el-descriptions-item__container{
			margin-left: 20px !important;
		}
		/deep/ .el-descriptions-item__label.has-colon::before{
			content: '*';
			position: relative;
			top: -1px;
			left: -4px;
			color: red;
		}
		/deep/ .el-descriptions-item__content{
			color: #aaa;
		}
	}

</style>